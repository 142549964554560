export const GET_CONTRACTOR_BILLING_REQUEST = "GET_CONTRACTOR_BILLING_REQUEST";
export const GET_CONTRACTOR_BILLING_SUCCESS = "GET_CONTRACTOR_BILLING_SUCCESS";
export const GET_CONTRACTOR_BILLING_ERROR = "GET_CONTRACTOR_BILLING_ERROR";

export const GET_DEPARTMENT_BILLING_REQUEST = "GET_DEPARTMENT_BILLING_REQUEST";
export const GET_DEPARTMENT_BILLING_SUCCESS = "GET_DEPARTMENT_BILLING_SUCCESS";
export const GET_DEPARTMENT_BILLING_ERROR = "GET_DEPARTMENT_BILLING_ERROR";

export const GET_BOOKING_STATISTICS_REQUEST = "GET_BOOKING_STATISTICS_REQUEST";
export const GET_BOOKING_STATISTICS_SUCCESS = "GET_BOOKING_STATISTICS_SUCCESS";
export const GET_BOOKING_STATISTICS_ERROR = "GET_BOOKING_STATISTICS_ERROR";

export const CLEAR_REPORTS_ACTION_STATUS = "CLEAR_REPORTS_ACTION_STATUS";

export const DOWNLOAD_ALL_REPORTS_REQUEST = "DOWNLOAD_ALL_REPORTS_REQUEST";
export const DOWNLOAD_ALL_REPORTS_SUCCESS = "DOWNLOAD_ALL_REPORTS_SUCCESS";
export const DOWNLOAD_ALL_REPORTS_ERROR = "DOWNLOAD_ALL_REPORTS_ERROR";

export const CHANGE_BOARDING_STATUS_REQUEST = "CHANGE_BOARDING_STATUS_REQUEST";
export const CHANGE_BOARDING_STATUS_SUCCESS = "CHANGE_BOARDING_STATUS_SUCCESS";
export const CHANGE_BOARDING_STATUS_ERROR = "CHANGE_BOARDING_STATUS_ERROR";

export const GET_UTILIZATION_REPORT_ONE_REQUEST =
  "GET_UTILIZATION_REPORT_ONE_REQUEST";
export const GET_UTILIZATION_REPORT_ONE_SUCCESS =
  "GET_UTILIZATION_REPORT_ONE_SUCCESS";
export const GET_UTILIZATION_REPORT_ONE_ERROR =
  "GET_UTILIZATION_REPORT_ONE_ERROR";

export const GET_UTILIZATION_REPORT_TWO_REQUEST =
  "GET_UTILIZATION_REPORT_TWO_REQUEST";
export const GET_UTILIZATION_REPORT_TWO_SUCCESS =
  "GET_UTILIZATION_REPORT_TWO_SUCCESS";
export const GET_UTILIZATION_REPORT_TWO_ERROR =
  "GET_UTILIZATION_REPORT_TWO_ERROR";

export const GET_UTILIZATION_REPORT_THREE_REQUEST =
  "GET_UTILIZATION_REPORT_THREE_REQUEST";
export const GET_UTILIZATION_REPORT_THREE_SUCCESS =
  "GET_UTILIZATION_REPORT_THREE_SUCCESS";
export const GET_UTILIZATION_REPORT_THREE_ERROR =
  "GET_UTILIZATION_REPORT_THREE_ERROR";

export const GET_UTILIZATION_REPORT_FOUR_REQUEST =
  "GET_UTILIZATION_REPORT_FOUR_REQUEST";
export const GET_UTILIZATION_REPORT_FOUR_SUCCESS =
  "GET_UTILIZATION_REPORT_FOUR_SUCCESS";
export const GET_UTILIZATION_REPORT_FOUR_ERROR =
  "GET_UTILIZATION_REPORT_FOUR_ERROR";
